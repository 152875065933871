.hover-btns {
   display: none;
   position: absolute;
   top: 0;
   left: 0;
   right: 0; 
   bottom: 0;
   z-index: 10;
   background-color: rgba(0,0,0,0.2);
   opacity: 0;
   transition: opacity 1s ease-in;

}
.hover-btns__inner {
    display: flex;
    flex-wrap: no-wrap;
    width: 100%;
    height: 100%; 
    flex-direction: row;
    align-items: center;
    justify-content: center;
 }
.hover-btn {
    color: #fff;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    text-align: center;
}

.has-hover-btns {
    position: relative;
}
.has-hover-btns:hover .hover-btns, 
.has-hover-btns:active .hover-btns,
.has-hover-btns:focus .hover-btns {
    display: block;
    opacity: 1;
}

.hover-btns__inner {
    .card__play {
        font-size: 70px;
    }
    .card__share {
    
    }
}
//tabindex="0" aria-haspopup="true"

.card-video {

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}