@import "@/assets/sass/mixins/index";

.home-banner {
    background-image: url(./images/home-main-bg.jpg);    
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-top: 59px;
    padding-bottom: 55px;

    @include breakpoint(m) {
        padding-top: 158px;
        padding-bottom: 36px;
        background-image: url(./images/home-main-bg-mob.jpg); 

        .head {
            margin-bottom: 14px;
        }
    }

    &__quote {        
        font-size: 18px;
        font-weight: 500;        
        line-height: 1.4;
        font-style: italic;
        margin-bottom: 30px;

        &-author {
            font-weight: 300; 
            font-style: normal;
        }
    }
    &__info {    
        font-size: 14px;
        font-weight: 300;        
        line-height: 1.5;
        max-width: 780px;
        margin: 0 auto 40px auto;

        @include breakpoint(m) {
           display: none;
        }

        p {            
            margin-bottom: 20px;
        }
        strong {
            font-weight: 500; 
        }
        
    }
    &__btns {
        
    }

}

.home-section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: stretch;
    align-items: stretch;
    background-color: #000;

    @include breakpoint(m) {
        display: block;
        position: relative;        
    }
    

    &__col {
        width: 50%;
        flex: 50%;
        min-width: 50%;

        @include breakpoint(m) {
            width: 100%;
            flex: 100%;
            min-width: 100%;
            
        }
    } 
    &__text {
        height: 100%;        
        width: 100%;
        max-width: 480px; 
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 80px;
        padding-bottom: 80px;
        z-index: 2;
        position: relative;

        @include breakpoint(m) {
            text-align: center;
        }
        
    } 
    &__title {
        font-size: 43px;
        font-weight: 500;        
        line-height: 1.2;
        text-transform: uppercase;
        margin-bottom: 16px;

        @include breakpoint(m) {
            font-size: 28px;
        }
        
    } 
    &__body{
        font-size: 16px;
        font-weight: 300;
        p {
            margin-bottom: 24px;
        }        
    } 
    &__btns {
        margin-top: 24px;
        .btn-primary {
            min-width: 185px;
        }
    } 
    &__img {
        min-height: 590px;            
        width: calc(100% + 50px);
        background-repeat: no-repeat;
        background-size: cover;

        @include breakpoint(m) {
            width: 100%;  
            min-height: 1px; 
            height: 100%;         
        }
        
    }

}
.home-section.home-section_left-img {
    .home-section{
        &__text {
            padding-left: $grid-gutter-width / 2;
            padding-right: $grid-gutter-width;
            margin-right: auto;
            text-align: left;

            @include breakpoint(m) {
                text-align: left;
            }
            
        }
        &__img {
            margin-right: -50px;
            background-position: 100% center;
            padding-left: $grid-gutter-width;

            @include breakpoint(m) {
                margin-right: 0;
                background-position: top center;
            }
        }
    }

}
.home-section.home-section_right-img {

    .home-section{
        &__text {
            padding-left: $grid-gutter-width;
            padding-right: $grid-gutter-width / 2;
            margin-left: auto;
            text-align: right;

            @include breakpoint(m) {
                text-align: left;
                padding-right: $grid-gutter-width
            }
            
        }
        &__img {
            margin-left: -50px;
            background-position: 0 center;

            @include breakpoint(m) {
                margin-left: 0;
                background-position: top center;
            }
        }
    }

}

@include breakpoint(m) {
    .home-section.home-section_right-img .home-section__col:last-child,
    .home-section.home-section_left-img .home-section__col:first-child
    {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);;
        }


    }
    .home-section.home-section_right-img .home-section__col:first-child,
    .home-section.home-section_left-img .home-section__col:last-child
    {
        .home-section {
            &__text {
                min-height: 568px;
                align-items: flex-end;
                padding: 30px 16px;
                max-width: 100%;

                .btn-primary {
                    padding: 14px;
                    min-width: 170px;
                }

            }
        }
        
        
    }
    
}

#home-section-bg1 {
    background-image: url(./images/home-section1-bg.jpg); 
}
#home-section-bg2 {
    background-image: url(./images/home-section2-bg.jpg); 
}
#home-section-bg3 {
    background-image: url(./images/home-section3-bg.jpg); 
}
.home-releases {   
    padding-top: 48px;
    padding-bottom: 10px;
    background-color: #101010;

    .head {
        margin-bottom: 12px;
    }

    &__desc {
        max-width: 780px;  
        margin: 0 auto 30px auto;      
        font-size: 16px;
        font-weight: 300;        
        text-align: center;
    }
}