@import "@/assets/sass/mixins/index";

.shop-card {
    margin-bottom: 20px;

    &__content {
       @include make-row();
    }
    &__col {
        @include make-col(6);

        @include breakpoint(xs) {
            @include change-col-width(12);
        }
    }

    &__img {
        display: block;
        text-align: center;
        margin-bottom: 10px;
        position: relative;
        height: 0;
        padding-top: 100%;
        
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right:0;
            border-radius: 4px;
            overflow: hidden;
        }

    }
    &__col_info {
        text-align: left;
    } 
    &__title {
        display: block;       
        font-size: 18px;
        font-weight: 500;        
        line-height: 1.2;
        margin-bottom: 3px;

    }
    &__author {
        color: #C9C9C9;        
        font-size: 14px;
        font-weight: 300;        
        line-height: 1.4;
        
    }
    &__tags { 
        color: #C9C9C9;        
        font-size: 14px;
        font-weight: 300;        
        line-height: 1.4;
    }
    &__info {
        margin-top: 22px;
        li {
            color: #C9C9C9;
            font-size: 14px;
            font-weight: 300;        
            line-height: 1.4;
            margin-bottom: 8px;
            i {
              font-size: 16px; 
              width: 20px;
              display: inline-block;  
              margin-right: 4px;
            }
            span {

            }
        }
    }

    
}
.shop-card.shop-card_with-btn {
    .shop-card__col_info {
        position: relative;
        padding-bottom: 50px;
    }
    .btn-buy__blc {
        position: absolute;
        bottom: 10px;

    }
}

