@import "@/assets/sass/mixins/index";

.modal-card {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    margin-top: 26px;
    margin-bottom: 30px;

    &__col {

    }

    &__col_img {
        padding-right: 15px;
        width: 38%;
        flex: 38%;
        margin-bottom: 10px;
    }
    &__img {
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 4px;
        overflow: hidden;
        background-color: #000000;
        box-shadow: 0px 3px 13px rgba(0, 0, 0, 0.453371);
    }
    &__col_info {       
        width: 62%;
        flex: 62%;
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;        
        color: #FFFFFF;
    }
    &__basic {
        font-size: 14px;
        line-height: 1.2;
        color: #C9C9C9;
        margin-bottom: 4px;
    }
    &__features {
        margin-top: 20px;
        li {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: flex-start;
            line-height: 1.4;
            font-size: 14px;
            color: #FFFFFF;
            margin-bottom: 10px;

            strong {
                font-weight: 500;
            }
            span {
                font-weight: 400;
            } 
        }
        
    }    
}
