.videos-blc {
    @include make-row();
    align-items: stretch;
}

.video-card {
    @include make-col(6);
    margin-bottom: 28px;

    // @include breakpoint(tx) {
    //     @include change-col-width(4);
    // }
    // @include breakpoint(m) {
    //     @include change-col-width(6);
    // }
    @include breakpoint(tx) {
        @include change-col-width(12);
    }
}
