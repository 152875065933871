.text-page {
    background-color: #121212;
    padding-top: 60px;
    padding-bottom: 48px;
    &__body {
        font-size: 14px;
        font-weight: 300;
        text-align: left;

        h4, h5, h6 {
            text-align: center;
            font-weight: 500;
        }
        h4 {
            font-size: 18px;
            margin-bottom: 20px;
        }
        p{
            margin-bottom: 16px;
        }

        a {
            color: $red-color;
            text-decoration: underline;

            &:visited, &:active {
                color: $red-color;
            }
        }
        ul { 
            list-style-type: disc; 
            list-style-position: inside; 
            padding-left: 20px;
         }
         ol { 
            list-style-type: decimal; 
            list-style-position: inside; 
         }
        li {
            list-style-type: disc;
            list-style-position: outside;
            
           
        }
    }
}

.closed-page {
    //color: #fff;
    //background-color: #EB453B;

    &__inner {
        padding-top: 20px;

    } 
    &__img {
        max-width: 400px;
        margin: 0 auto 30px;
        text-align: center;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__body {
        padding-bottom: 40px;
        h2, h1 {
            text-align: center;
            font-size: 32px;
            font-weight: 500;
            line-height: 1.2;
            margin-bottom: 28px;
            text-transform: uppercase;

            @include breakpoint(m) {
                font-size: 26px;
                margin-bottom: 24px;
            }
        }
    

        h3, h4 {
            font-size: 24px;
            font-weight: 500;
            line-height: 1.2;
            margin-bottom: 20px;

            @include breakpoint(m) {
                font-size: 22px;
                margin-bottom: 18px;
            }
        }

        p {
            margin-bottom: 8px;
            font-weight: 300;
        }

        strong {
            font-weight: 600;
        }

        p ~ h2, p ~ h1 {
            margin-top: 46px;
        }

        p ~ h3, p ~ h4 {
            margin-top: 28px;
        }
        mark {
            background-color: transparent;
            color: $red-color;
        }
        h5 {
            text-align: center;
            margin-top: -20px;
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 20px;
        }
    }
}
.closed-page__bg {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .closed-page__body {
        padding-top: 30px;
        section{
            padding: 24px;
            background-color: rgba(57, 56, 56, 0.7);
            margin-bottom: 40px;
            border: 1px solid rgba(57, 56, 56, 0.7);
            border-radius: 8px;
        }
    }
}