@mixin breakpoint($point) {

    @if $point == xs {
        @media (max-width: 420px) {
        @content;
        }
    }
    @else if $point == s {
        @media (max-width: 640px) {
        @content;
        }
    }
    @else if $point == m {
        @media (max-width: 768px) {
        @content;
        }
    }
    @else if $point == t {
        @media (max-width: 1024px) {
        @content;
        }
    }
    @else if $point == tx {
      @media (max-width: 940px) {
      @content;
      }
    }
    @else if $point == l {
        @media (max-width: 1200px) {
        @content;
        }
    } 
    @else if $point == xl {
        @media (min-width: 1600px) {
        @content;
        }
    } 
    
}
$grid-gutter-width : 20px;

@mixin make-row(
  $gutter: $grid-gutter-width,  // растояние между колонками сетки
  $max-width: null,           // по умолчанию максимальная ширина определяется родителем-контейнером
  $justify: space-between,    // по умолчанию пустота заполняется между колонками
  $align: flex-start,          // по умолчанию в строке элементы выравниваются по верхнему краю
  
)
{
  display: flex;
  flex-flow: row wrap;
  justify-content: $justify;
  align-content: $align;

  @if type-of($gutter) == 'map' { // если gutter задан как breakpoint map
    @each $breakpoint, $value in $gutter {
      $margin: ($value / -2);

      @include breakpoint($breakpoint) {
        margin-left: $margin;
        margin-right: $margin;
      }
    }
  }
  @else {                   // если gutter задан как число
    margin-left: ($gutter / -2);
    margin-right: ($gutter / -2);
  }

  @if $max-width {
    max-width: $max-width;
  }
}

// вычисляем ширину колонки в процентах
// $col-nums - число колонок, которое колонка занимает по сетке
// если $col-nums не передан - колонка занимает всю строку
@function get-col-width($col-nums, $grid-columns: 12)
{
  $col-width: auto;

  @if $col-nums {
    $col-width: percentage(($col-nums / $grid-columns));
  }
  @else {
    $col-width: 100%;
  }

  @return $col-width;
}

// создать flex-колонку
@mixin make-col(
  $columns      : null,               // число занимамых колонок по сетке
  $gutter       : $grid-gutter-width,  // растояние между колонками сетки
  $grid-columns: 12
)
{

  @if type-of($gutter) == 'map' { // если gutter задан как breakpoint map
    @each $breakpoint, $value in $gutter {
      $padding: $value / 2;

      @include breakpoint($breakpoint) {
        padding-left: $padding;
        padding-right: $padding;
      }
    }
  }
  @else {                   // если gutter задан как число
    $padding: $gutter / 2;
    padding-left: $padding;
    padding-right: $padding;
  }
  $col-width: get-col-width($columns, $grid-columns);
    width: $col-width;
    flex-basis: $col-width;
}


@mixin change-col-width(
  $columns      : null,               // число занимамых колонок по сетке
  //$gutter       : $grid-gutter-width,  // растояние между колонками сетки
  $grid-columns: 12
)
{
  
  $col-width: get-col-width($columns, $grid-columns);
    width: $col-width;
    flex-basis: $col-width;
}