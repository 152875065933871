@import "@/assets/sass/mixins/index";
@import "@/assets/sass/mixins/vars";

.top-scroll__el_dashboard-table {
    @include breakpoint(m) {       
        min-width: 640px;
        height: 20px;
    }
}

.dashboard-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0px 10px;

    @include breakpoint(m) {
        width: auto;
        min-width: 640px;
        //position: relative;
        
    }
    &__wrap {

        @include breakpoint(m) {
            overflow-x:auto;
            white-space: nowrap;
        }
    }

    thead {
        line-height: 1.2;

        
        th {
            vertical-align: middle;
            color: #92959D;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            text-transform: uppercase; 
            padding: 0 8px;
            
            &:first-child {
                padding-left: 16px;
            }
            &:last-child {
                padding-right: 16px;
            }

            @include breakpoint(m) {
                // position: -webkit-sticky;
                // position: sticky;
                // top: 0;
                // z-index: 2;
            }
            
        }
    }

    tbody {
        .dashboard-table__card {  
            td {
                border-top: 1px solid rgba(255,255,255, 0.13);
                border-bottom: 1px solid rgba(255,255,255, 0.13);
                vertical-align: middle;
                padding: 18px 8px;
                @include long-word-break();
            }          
            td:first-child {
                border-left: 1px solid rgba(255,255,255, 0.13);
                border-radius: 6px 0 0 6px ;
                padding-left: 16px;
            }
            td:last-child {
                border-right: 1px solid rgba(255,255,255, 0.13);
                border-radius: 0 6px 6px 0;
                padding-right: 16px;
            }
        }
        .dashboard-table__card-top {  
            td {
                border-top: 1px solid rgba(255,255,255, 0.13);               
                vertical-align: middle;
                padding: 18px 8px 7px 8px;
            }          
            td:first-child {
                border-left: 1px solid rgba(255,255,255, 0.13);
                border-radius: 6px 0 0 0 ;
                padding-left: 16px;
            }
            td:last-child {
                border-right: 1px solid rgba(255,255,255, 0.13);
                border-radius: 0 6px 0 0;
                padding-right: 16px;
            }
        }
        .dashboard-table__card-bottom {  
            td {                
                border-bottom: 1px solid rgba(255,255,255, 0.13);
                vertical-align: middle;
                padding: 7px 8px 18px 8px;
            }          
            td:first-child {
                border-left: 1px solid rgba(255,255,255, 0.13);
                border-radius: 0 0 0 6px ;
                padding-left: 16px;
            }
            td:last-child {
                border-right: 1px solid rgba(255,255,255, 0.13);
                border-radius: 0 0 6px 0;
                padding-right: 16px;
            }
        }

    }
}
.dashboard-table__wrap {
    margin-bottom: 30px;
}

.header-order {
    color: inherit;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    
    &__label {
        
    }

    .btns-sort {
        &_asc {
            .btn-sort.btn-sort_up{
                color: #ffffff;
            }

        }
        &_desc {
            .btn-sort.btn-sort_down {
                color: #ffffff;
            }

        }
        
    }
    
}
.dashboard-table {
    .db-multi {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        justify-content: space-between; 
        &__fixed {
            width: 100px;
        }
        &__flow {            
        }

    }

    .status {
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        
        // &_success {
        //     color: $green-status;
        // } 
        // &_pending {
        //     color: $yellow-status;
        // }
        // &_fail {
        //     color: $red-status;
        // }        
    }

    .text-rows {

        &__title {
            font-weight: 500;
            font-size: 18px;
            line-height: 1.5;
            color: #ffffff;
        }
        &__sub {
            font-weight: 400;
            font-size: 14px;
            line-height: 1.4;
            color: #C9C9C9;
        }
    }

    .note-text {
        font-style: italic;
        font-size: 14px;
        line-height: 1.57;
        @include long-word-break();

        &__label {
            font-weight: 500;
        }
    }

}

.dashboard-right-table {
    margin-left: auto;

    &__wrap {
        margin-bottom: 38px;
    }
    tbody, tfoot {
        td {
            text-transform: uppercase;
            line-height: 1.2;
            text-align: right;
            padding: 9px 10px;

            &:last-child {
                text-align: left;                
            }

        }
    }
    tbody {
        td {
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
        }
    }
    tfoot {
        td {
            color: #FF2E2E;
            font-weight: 500;
            font-size: 22px;
        }
    }
}
.db-features-table {
    width: 100%;
    color: #FFFFFF;
    &__wrap {
        margin-bottom: 20px;
    }
    td {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 0.355556px;
        padding: 7px 9px;
        @include long-word-break();

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

    }
    &__label {
        width: 40%;
        text-transform: uppercase;
    }
}

.db-price-col {
    width: 180px;
    text-align: left;
    padding-right: 16px;
    box-sizing: border-box;
}
.db-price-green {
    color: #219E6C;
    font-size: 0.9em;
}
