.pagination {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;    
    .btn {
        background-color: transparent;
        color: #ffffff
    }
    .pagination__arrow {
        font-size: 8px;
        padding: 0 8px; 

        &:hover {
            color: #FF2E2E;
        }
        // &_prev {
        //     padding-right: 5px;
        // }
        // &_next {

        // }       
    }
    .pagination__link {
        font-size: 16px;
        font-weight: 500;
        padding: 0 5px;

        &:hover {
            color: #FF2E2E;
        }
    }
    span.pagination__link {
        cursor: default; 
        &:hover {
            color:#ffffff;
        }
    }
    .pagination__link_active {
        cursor: default;
        color: #FF2E2E;
    }
}