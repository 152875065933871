@mixin clearfix() {
    &::after {
        content: ' ';
        display: table;
        clear: both;
    }
}

@mixin input-placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &:-ms-input-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}

@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false){
    @include pseudo($pos: $position);
    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-top: $size solid $color;
        margin-top: 0 - round( $size / 2.5 );
    } @else if $direction == up {
        border-left: $size solid transparent;
        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        margin-bottom: 0 - round( $size / 2.5 );
    } @else if $direction == right {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
        margin-right: -$size;
    } @else if  $direction == left {
        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-right: $size solid $color;
        margin-left: -$size;
    }
}

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin image-2x($image, $width, $height) {
    @media (min--moz-device-pixel-ratio: 1.3),
           (-o-min-device-pixel-ratio: 2.6/2),
           (-webkit-min-device-pixel-ratio: 1.3),
           (min-device-pixel-ratio: 1.3),
           (min-resolution: 1.3dppx) {
      background-image: url($image);
      background-size: $width $height;
    }
  }  
  
  @mixin center-vertically {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }  
  
  @mixin text-shorten($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}   

@mixin responsive-ratio($x,$y, $pseudo: false) {
    $padding: unquote( ( $y / $x ) * 100 + '%' );
    @if $pseudo {
        &:before {
            @include pseudo($pos: relative);
            width: 100%;
            padding-top: $padding;
        }
    } @else {
        padding-top: $padding;
    }
}

@mixin inline-middle {
    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

@mixin crome-auto($text: #000, $bg: #fff) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus,
    &:-internal-autofill-selected {
        -webkit-text-fill-color: $text;
        background-color: $bg !important;       
        transition: background-color 5000s ease-in-out 0s;
        -webkit-box-shadow: 0 0 0px 100px $bg inset;

        @content;
        
    }
    
}

// local style

@mixin square-img {
    display: block;
    text-align: center;   
    position: relative;
    height: 0;
    padding-top: 100%;

    img {
        object-fit: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right:0;        
        overflow: hidden;
    }
}

@mixin long-word-break {
    overflow-wrap: break-word;
    word-wrap: break-word; 
    word-break: break-word;
    hyphens: auto;
}