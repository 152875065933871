.form-row {
    @include make-row();    
}

.form-col_half {
    @include make-col(6);

    @include breakpoint(m) {
        @include change-col-width(12);
    }
}

.form-row.form-row_sided {
    justify-content: space-between;
    .form-col:nth-of-type(2n)  {
        text-align: right;
        
    } 
     .form-col:nth-of-type(2n +1)  {
        text-align: left;
     }  
}
$padding: $grid-gutter-width / 2;
.form-col {
    padding-left: $padding;
    padding-right: $padding;
}


.not-found-blc {
    min-height: 50vh;
    text-align: center;
    padding-top: 50px;

    p { 
        font-size: 18px;
    }
}
