* {
    margin: 0;
    padding: 0;
    font-size: 100%;
    box-sizing: border-box;
    outline: 0;
    vertical-align: baseline;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }
  
  
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
      display: block;
  }
  
  html {
      font-size: 100%;
      -ms-text-size-adjust: 100%;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  } 
  
  /* tables list*/
  ol, ul {
      list-style: none;
  }
  
  li {
      list-style-type: none;
  }
  table {
      border-collapse: collapse;
      border-spacing: 0;
  }
  td, td{
      vertical-align: top;
  }
  
  /* others*/
  blockquote, q {
      quotes: none;
  }
  
  blockquote:before, blockquote:after,
  q:before, q:after {
      content: '';
      content: none;
  }
  sup {
      vertical-align: super;
      font-size: smaller;
  }
  audio,
  progress,
  canvas,
  video {
      display: inline-block;
      vertical-align: baseline;
      max-width: 100%;
  }
  audio:not([controls]) {
      display: none;
      height: 0;
  }
  
  img {
    border: none;
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
     vertical-align: top;
  }
  
  /* forms */
  
  :focus {
      outline: 0;
  }
  input[type=search]::-webkit-search-cancel-button,
  input[type=search]::-webkit-search-decoration,
  input[type=search]::-webkit-search-results-button,
  input[type=search]::-webkit-search-results-decoration {
      -webkit-appearance: none;
      -moz-appearance: none;
  }
  
  input[type="search"] {
      -webkit-appearance: none;
      -moz-appearance: none;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
  }
  
  textarea {
      overflow: auto;
      vertical-align: top;
      resize: vertical;
  }
  
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none;
  }
  textarea {
      resize: vertical;
  }
  input,
  button,
  textarea,
  input:active,
  input:focus,
  textarea:active,
  textarea:focus {
    outline: none;
    -webkit-appearance: none;
  }
  button::-moz-focus-inner,
  input[type="reset"]::-moz-focus-inner,
  input[type="button"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="submit"]::-moz-focus-inner,
  input[type="file"] > input[type="button"]::-moz-focus-inner {
    border: none;
    outline: none;
  }
  
  /* link buttons*/
  
  button,
  html input[type="button"], 
  input[type="reset"],
  input[type="submit"] {
      -webkit-appearance: button; 
      cursor: pointer; 
      overflow: visible; 
  }
  
  button[disabled],
  html input[disabled] {
      cursor: default;
  }
  
  button::-moz-focus-inner,
  input::-moz-focus-inner {
      border: 0;
      padding: 0;
  }
  a {
    color: inherit;
    transition: all 0.3s;
  }
  a,
  a:hover {
    text-decoration: none;
  }
  
  a:active,
  a:hover,
  a:focus {
    outline: none;
  }
  