.note-modal {
    .main-modal__container {
        max-width: 426px;
        text-align: center;
    }
    &__icon {          
        text-align: center;
        margin-bottom: 16px;
        line-height: 1;
        .as-icon {
            font-size: 100px; 
        }
        
    }
    &__icon.as-success  {
            color: #20B320;
    }
    &__icon.as-error,  &__icon.as-alert, &__icon.as-saved{
        color: #FF2E2E;
    }

    &__icon.as-saved{
        margin-top: 20px; 
        .as-icon {
            font-size: 92px;           
        }
        img {
            max-width: 150px;
        }
    }
    &__title {
        font-size: 22px;
        line-height: 1.2;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        font-weight: 400;
        margin-bottom: 16px;
    }

    &__msg {
        font-size: 16px;
        line-height: 1.62;
        text-align: center;
        color: #FFFFFF;
        font-weight: 600;
        margin-bottom: 16px;
    }

    &__btn {
        text-align: center;
        .btn {
            min-width: 168px;
        }
    }
    .btn-second {        
        padding: 12px 38px;
    }
    &__btns {        
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn {
            width: 48%;
        }
        .btn-second, .btn-primary {        
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    &__btn-cols {
        .btn {
            width: 100%;
            display: block;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

   
}


