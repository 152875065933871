@import "@/assets/sass/mixins/index";

.albom-item{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    line-height: 1.2;
    border-bottom: 1px solid rgba(236, 236, 236, 0.26);
    padding: 2px 0;

    &:last-child {
        border-bottom: none;
    }
    @include breakpoint(xs) {
        flex-wrap: wrap;
    }

    &__number{
        text-align: center;
        color: #C9C9C9;
        font-size: 12px;
        font-weight: 300;
        width: 32px;
        height: 32px;
        line-height: 30px;        
    } 
    &__title{
        text-align: left;
        flex-grow: 1;        
        font-size: 16px;
        padding: 0 10px;

        @include breakpoint(xs) {
            width: calc(100% - 33px);
        }
    } 
    &__duration{
        text-align: right;
        color: #C9C9C9;
        font-size: 12px;
        font-weight: 300;
        white-space:nowrap;

        .mini-progress {
            margin-right: 20px;
        }
    } 
    &__btns{
        text-align: right;
        width: 70px;       
    }

} 

.mini-progress {
    display: inline-block;
    width: 64px;
    height: 1px;
    position: relative;
    border-radius: 1px;
    background-color: rgba(179,179,179,0.27);   

    &__ready {
        position: absolute;
        border-radius: 1px;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: #D0021B;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: -3px;
            height: 7px;
            width: 7px;
            border: 1px solid #D0021B;
            background-color: #000000;
            box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
            border-radius: 50%;
        }

    }
}