.alboms-blc {
    @include make-row(16px);
    align-items: stretch;
    justify-content: flex-start;
}

.albom-card {
    @include make-col(1, 16px, 5);
    margin-bottom: 28px;

    @include breakpoint(m) {
        @include change-col-width(3, 12);
    }
    @include breakpoint(s) {
        @include change-col-width(4, 12);
    }
    @include breakpoint(xs) {
        @include change-col-width(6, 12);
    }
}
