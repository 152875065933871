.login-form {
    .form-row_sided {
        .link {
            display: inline-block;
            vertical-align: top;
            line-height: 1;
        }
        .form-col.form-col_half {
            @include breakpoint(m) {
                @include change-col-width(6);                
            }
        }
    }
}
.signup-form {
    .form-submit-row {
        align-items: center;
        margin-top: 30px;
        margin-bottom: 80px;

        @include breakpoint(m) {
            margin-bottom: 40px;
        }

        .btn-primary {
            padding: 13px 42px;
        }


        .form-col {
            @include breakpoint(m) {
                @include change-col-width(12);
                margin-bottom: 10px;
                text-align: center;
            }
        }
    }
}