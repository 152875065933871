.form-select .MuiInputBase-root,
.form-duration .MuiInputBase-root,
.form-datepicker .MuiInputBase-root,
.form-autocomplete .MuiInputBase-root
{
    box-sizing: border-box;       
    font-family: $main-font;
    width: 100%;
    background-color: #ffffff;
    padding: 5px 12px;
    font-size: 16px;
    font-weight: 300;        
    line-height: 1.2;
    color: #000000; 
    border: 1px solid #FFFFFF;
    border-radius: 5px;
    min-height: 35px;
    border: 1px solid #ffffff;

    * {
        box-sizing: border-box;
    }

}
.form-autocomplete .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 5px 12px;
}
.form-datepicker .MuiInputBase-root, .form-duration .MuiInputBase-root{
    padding-right: 0;
}
.form-field.form-duration {   
    .MuiFormControl-root {
        width: 100%;
    }
    .duration-input {
        .MuiInputBase-input  {
            padding: 0;
        }
        
    }

}
.form-field.form-datepicker {
    .MuiFormControl-root {
        width: 100%;
    }
    .datepicker-input {
        .MuiInputBase-input  {
            padding: 0;
        }
    }
}

.MuiDialog-root.duration-dialog {
   
    * {
        font-family: $main-font;
    }
    .MuiPickersToolbar-toolbar {
        background-color: $red-color;
    }
    .MuiButtonBase-root {
        color: $red-color;
    }
    .MuiPickersClockPointer-pointer, .MuiPickersClock-pin {
        background-color: $red-color;
    }
    .MuiPickersClockPointer-noPoint {
        background-color: #101010;
    }
    .MuiPickersClockPointer-thumb {
        border-color: $red-color;
    }
}

.MuiDialog-root.datepicker-dialog {
   
    * {
        font-family: $main-font;
    }
    .MuiButtonBase-root {
        color: $red-color;
    }
    .MuiButtonBase-root.MuiPickersDay-day {
        color: #101010;
    }
    .MuiPickersDay-daySelected {
        color: #fff;        
        background-color: $red-color;
    }
}


.form-select, .form-duration, .form-datepicker, form-autocomplete{
    .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
    
    .MuiSelect-nativeInput {
        font-family: $main-font;
        background-color: #ffffff;
        font-size: 16px;
        font-weight: 300;        
        line-height: 1.2;
        color: #000000; 
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        //border-width: 2px;
    }
    .MuiSvgIcon-root {
        color:   #000000;
        fill:  #000000;
        path{            
            fill: #000000;
        }

    } 
    .MuiSelect-icon {
        margin-left: 2px;
    } 
    &.error {

        .MuiInputBase-root {
            border-color: $red-color;
        }
    }   

}


.MuiSelect-select.field-select {
    font-size: 16px;
    font-weight: 300;        
    line-height: 1.2;
    color: #000000; 
    padding: 0;

    &:focus {
        background-color: transparent;
    }
     
}
.MuiMenu-paper.field-select__opts-wrap, .MuiMenu-paper.as-select__opts-wrap{

    &.MuiPaper-rounded {
        border-radius: 3px;
    }


}
.MuiList-root.MuiMenu-list.field-select__opts, .MuiList-root.MuiMenu-list.as-select__opts {
    
    .MuiMenuItem-root {
        font-family: $main-font;
        color: #000000;  
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.19;
        padding: 6px 12px;

        &.Mui-selected {
            background-color: $red-color;
        }
    }
    .MuiAutocomplete-listbox {
        font-family: $main-font;
        color: #000000;  
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.19;
        padding: 6px 12px;

        li[aria-selected="true"] {
            background-color:$red-color;
        }
    }

}
//autocomplete 
.form-autocomplete {
    .MuiSvgIcon-root {
        color:   #000000;
        fill:  #000000;
        path{            
            fill: #000000;
        }
    }
}

//common remove native styles

.as-select {

    .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
    }
    .MuiSelect-nativeInput {
        font-family: $main-font;
    }
    .MuiAutocomplete-tag {
        height: auto;
        color: #fff;
        font-family: $main-font;
        background-color: $red-color;
        border-radius: 6px;
        padding: 2px 4px;
        font-size: 16px;
        font-weight: 300; 
        line-height: 1;

        .MuiChip-deleteIcon {
            color:  #fff;
            fill:  #fff;
            path{            
                fill: #fff;
            }
        }
    }
     &.Mui-focused {
        box-shadow: 0 0 5px 0 rgba(255,46,46,0.79);
     }
    //
}
.as-many-autocomplete {
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        width: auto;	
    }   
}
.as-many-autocomplete, .as-one-autocomplete {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0 4px;
        height: 19px;
    }
}

.form-field.error {
    .as-select {
        .MuiInputBase-root {
            border-color: $red-color;
        }
    }
}

.as-select__opts-imgs {
    .MuiAutocomplete-option {
        .img-opt {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            &__img {               
                width: 20px;
                height: 20px;
                object-fit: cover;
                object-position: center;
                border-radius: 50%;
                margin-right: 8px;               
            }
            &__label {
                font-size: 16px;
            }
        }
    }
}
