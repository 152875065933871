input, textarea {
    font-family:  $main-font;
}

.form-field {    
    margin-bottom: 20px;

    .label {
        display: block;
        color: #FFFFFF;       
        font-size: 17px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.2;
        margin-bottom: 5px;
    }
    .label_required::after {
        content: '*';
        margin-left: 8px;
        color: $red-color;
    }
    .input, .textarea {
        display: block;
        width: 100%;
        font-size: 16px;
        font-weight: 300;        
        line-height: 1.2;
        color: #000000; 
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        background-color: #FFFFFF;
        padding: 5px 12px;

        @include input-placeholder() {
            color: #4A4A4A;
            font-weight: 300;      
        }
        &:focus {
            box-shadow: 0 0 5px 0 rgba(255,46,46,0.79);
        }
    }
    .textarea {
        min-height: 180px;
        padding: 16px 12px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }
    .form-field__note {
        color: #D4D4D4; 
        font-size: 13px;
        font-style: italic;
        font-weight: 500;
        letter-spacing: 0.41px;
        line-height: 1.2em;
        margin-top: 6px;

        &.error {
            color: $red-color;
        }
        &.valid {
            color: $green-color; 
        }
        &.empty {
            min-height: 1.2em;
        }
    }
    &.error {
        .input, .textarea {
            border-color: $red-color;
        }
    }
    .label-row {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(m) {
            flex-direction: column;
            .label {
                width: 100%;
            }
        }
        .label-note {
            font-size: 18px;
            font-weight: 500;            
            line-height: 1.2;
            margin-bottom: 5px;
            .red {
                color: $red-color;
            }

            @include breakpoint(m) {
                width: 100%;
                flex: 100%;
            }
        }
    }
}

.form-field_password {
    .form-field {
        &__input-wrap {
            position: relative;

            .input {
                padding-right: 46px;

                &[type="password"] {
                    font-weight: 900;
                }
            }

            .btn-icon {
                position: absolute;
                width: 44px;
                right: 0;
                top: 0;
                bottom: 0;
                color: #D4D4D4;
                font-size: 16px;
                opacity: 0.7;
            }
        }
    }
}

.form-field__note.form-field__note_other.error {
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    letter-spacing: 0.41px;
    line-height: 1.2em;
    color: $red-color;
}
.checkbox-field {
    display: flex;
    align-items: center;

    &__input {
        display: none;
    }
    &__control {
        display: inline-block;
        position: relative;
        height: 20px;
        width: 20px;
        border-radius: 3px;
        background-color: #FFFFFF;         

        &::before {
            //font-family: 'as' !important;
            content: '\2714';
            top: 0;
            left: 2px;
            font-size: 20px;
            line-height: 1;
            position: absolute;
            opacity: 0;
            color: $green-color;
            transition: opacity 0.3s ease;
        }
    }
    &__label {
        color: #FFFFFF;        
        font-size: 16px;
        font-weight: 300;        
        line-height: 1.2;       
        padding-left: 10px;        
    }
    &__input:checked ~ &__control::before {
        opacity: 1;
    }
}

.radio-field {
    display: flex;
    align-items: center;

    &__input {
        display: none;
    }
    &__control {
        display: inline-block;
        position: relative;        
        height: 18px;
        width: 18px;
        border: 1px solid #FFFFFF;
        margin-right: 9px;
        border-radius: 50%;
        position: relative;
        transition: all 0.3s ease; 
        min-width: 18px;    

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 3px;
            left: 3px;
            transform: scale(0);
            border-radius: 50%;
            background-color: $red-color;
            transition: all 0.3s ease;
        }
    }
    &__label {
        color: #FFFFFF;        
        font-size: 16px;
        font-weight: 300;        
        line-height: 1.2;       
        padding-left: 10px;        
    }
    &__input:checked ~ &__control::before {
        transform: scale(1);
    }
}

.form-upload {
    text-align: center;
    //border: 1px dashed #FFFFFF;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='white' stroke-width='2' stroke-dasharray='4' stroke-dashoffset='2' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 4px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 21px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        width: 100%;
    }

    &__icon {
        font-size: 40px;
        margin-bottom: 16px;
    }
    &__text {
        font-weight: 500;
    }
    &__input {
        display: block;
        color: #D0021B;
        border: none;
        background-color: transparent;
        outline: none;

        input {
            display: none;
        }
    } 
    &__filename {
        font-weight: 300;
        word-break: break-all;
        word-break: break-word;
        hyphens: auto;
    } 
    &__mime {
        font-weight: 300;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        flex-flow: row nowrap;
    }
    &__col {
        
    }
    &__col_left {
        padding-right: 8px;
    }
    &__info {
        font-size: 14px;
        color: rgba(255,255,255,0.67);
    }
    &__info_white {
        font-size: 14px;
        color: #FFFFFF;
    }
    &__middle {
        height: 100%;
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
    }

    &__progress {
        width: 100%;
        margin-top: 4px;
        height: 8px;
        border-radius: 6px;
        padding: 1px;
        background-color: #FFFFFF;

        &-done {
            border-radius: 3px;
            background-color: $red-color;
            height: 100%;
            width: 0;
        }
    }
}

.form-upload.form-upload_img {
    padding: 20px;
    .form-upload {        

        &__text {
            min-height: 84px;
        }
    }

}
.form-upload.form-upload_audio {
    padding: 16px 20px;
    .form-upload {

        &__filename {
            font-weight: 500;
            word-break: break-all;
            word-break: break-word;
            hyphens: auto;
        } 
        
        &__icon {            
            margin-bottom: 10px;
        }

        &__text {            
            margin-bottom: 4px;
        }
        &__mime {
           opacity: 0.67;
        }

        &__input-area {
            display: block;

            input {
                display: none;
            }
        }
    }
    .form-upload__content.form-upload__content_fill {
        text-align: left;
        .as-icon.as-middle {
            font-size: 44px;
        }
        .as-icon.as-small {
            font-size: 16px;
        }
        .form-upload {

        }
    }

}

.form-field.form-field--red-label {
    .label {
        text-transform: uppercase;
        color: #D0021B;
    }
}
