.crates-blc {
    @include make-row();
    align-items: stretch;
    justify-content: flex-start;
}


.crate-card {
    @include make-col(4);
    margin-bottom: 30px;

    @include breakpoint(tx) {
        @include change-col-width(6);
    }
    @include breakpoint(m) {
        @include change-col-width(12);
    }
    // @include breakpoint(xs) {
    //     @include change-col-width(12);
    // }
}
