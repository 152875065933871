//TODO

$main-font: Renner, 'Jost', sans-serif;
$head-font: Impact, Oswald, Charcoal, sans-serif; 

$red-color: #FF2E2E;
$dark-color: #393838;
$light-color: #ffffff;
$green-color: #3EA401;
$darkred-color: #BF1616;
$black-color: #000000;
$cleanred-color: #FF0000;
$bg-color: #000000;

$red-status: #FF2E2E;
$yellow-status: #F5A623;
$green-status: #219E6C; 

$mobile-separator: 1px solid rgba(151, 151, 151, 0.25);

//background-color: #BCBCBC;
//background-color: #393838;
//#BCBCBC
//#D4D4D4
//rgba(255,46,46,0.79);
//#101010