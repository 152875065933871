.video-block {
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    position: relative;

    iframe, embed, object{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

.aside-video-wrap {
    .video-card {
        width: 100%;
        flex: 100%;
        padding-left: 0;
        padding-right: 0;
    }
}