@import "@/assets/sass/mixins/index";
@import "@/assets/sass/mixins/vars";

.shop-filter__item {
    padding-right: 20px;
    min-width: 150px;
    margin-bottom: 20px;

    .MuiInputBase-root {
        font-family: $main-font;
    }
    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        //border-width: 2px;
    }
    .MuiSvgIcon-root {
        color:  #FF2E2E;
        fill:  #FF2E2E;
        path{            
            fill:  #FF2E2E;
        }

    } 
    .MuiSelect-icon {
        margin-left: 2px;
    }    

}


.MuiSelect-select.filter-select {
    color: #FF2E2E;
    font-size: 22px;
    font-weight: 500;
    line-height: 1.2;
    font-family: $main-font;
    padding: 4px 8px;   
}
.MuiMenu-paper.filter-select__opts-wrap {

    &.MuiPaper-rounded {
        border-radius: 3px;
    }

}
.MuiList-root.MuiMenu-list.filter-select__opts {
    
    .MuiMenuItem-root {
        font-family: $main-font;
        color: #000000;  
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 1.19;
        padding: 6px 12px;

        &.Mui-selected {
            background-color: #FF2E2E;
        }
    }

}