.label-single {
    display: block;
    color: #FFFFFF;       
    font-size: 17px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1.2;
    margin-bottom: 5px;
}
.label-single_required::after {
    content: '*';
    margin-left: 8px;
    color: $red-color;
}

.addition-field {
    transition: max-height 0.3s ease;
    .form-field.form-field_words-limit {
        .textarea {
            max-height: 100px;
            min-height: auto;
            resize: none;
        }
    }

}
.addition-field_hide {
    //display: none;
    visibility: hidden;
    max-height: 0;
    overflow: hidden;   
}
.addition-field_show {
    max-height: 200px;    
}