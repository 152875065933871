.page {

}

.head-shadow {
    text-align: center;
    opacity: 0.34;
    font-family: $head-font;
    font-size: 72px;    
    line-height: 1.2;
    margin-bottom: -0.63em;
    text-transform: uppercase;

    @include breakpoint(m) {
        font-size: 42px;
        margin-bottom: -0.53em;
    }
}

.head {
    font-size: 43px;
    font-weight: 500;  
    line-height: 1.2;
    text-align: center;
    margin-bottom: 24px; 
    text-transform: uppercase;  

    @include breakpoint(m) {
        font-size: 33px;
        
    }
}

.center {
    text-align: center;
}

.head-middle {
    font-size: 32px;
    font-weight: 500;  
    line-height: 1.2;
    text-align: center;
    margin-bottom: 40px; 
    text-transform: uppercase;  
}

.lined-blc {
    position: relative;
    text-align: center;
    background-color: inherit;
    z-index: 0;

    &__txt {
        display: inline-block;
        background-color: #101010;
        color: rgba(255, 255, 255, 0.47);        
        font-size: 22px;
        font-weight: 500;
        line-height: 1;
        padding: 0 8px;
        z-index: 2;
    }  
    
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 1px;
        //background-color: #fff;
        z-index: -1;
        background-color: rgba(255, 255, 255, 0.47);
    }
}

.as-quote {
    position: relative;    

    &::before, &::after {
        display: inline-block;
        position: absolute;
        font-family: Georgia, Times, "Times New Roman", serif;
        content: '“';        
        width: 48px;
        //text-indent: -0.5em;
        opacity: 0.26;
        color: #FFFFFF;       
        font-size: 120px;
        font-style: italic;        
        line-height: 1;
        height: 60px;
        z-index: 0;
    }

    &::before {
        
    }
    &::after {
        transform: rotate(-180deg);
    }

}
.as-quote.as-quote_inline {
    margin: 15px 0 15px 15px;
    display: inline-block;

    &::before {
        top: -5px;
        margin-top: -25px;
        left: -47px;
        font-size: 5rem;
    }
    &::after {
        margin-left: 0px;
        bottom: -25px;
        font-size: 5rem;
    }
    @include breakpoint(m) {
        margin: 15px; 
        &::before, &::after {
            font-size: 60px;
        }
        &::before {
            top: 0;
            margin-top: -10px;
            left: -27px;            
        }
        &::after {
            margin-left: -10px;
            bottom: -10px;            
        }
    }

}

.as-quote.as-quote_block {
    display: block;
    padding-left: 30px;
    padding-right: 30px;
    font-style: italic;
    font-size: 18px;

    &::before {
        display: block;
        top: 0;
        margin-top: -12px;
        left: -12px;
    }
    &::after {
        display: block;
        margin-left: -6px;
        bottom: -16px;
        right: 0px;        
    }
    p {
        margin-bottom: 26px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}
.as-quote__sign {
    font-size: 16px;
    padding-left: 30px;
    .red {
        color: #FF2E2E
    }
}

.as-text {
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 1.5625;
}

.as-red {
    color: $red-color;
}