.samples-blc {
    @include make-row();
    align-items: stretch;
}

.sample-card {
    @include make-col(3);
    margin-bottom: 30px;

    @include breakpoint(tx) {
        @include change-col-width(4);
    }
    @include breakpoint(m) {
        @include change-col-width(6);
    }
    // @include breakpoint(xs) {
    //     @include change-col-width(12);
    // }
}
