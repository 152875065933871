@font-face {
    font-family: 'as';
    src:  url('../../fonts/as.eot?vtd0fj');
    src:  url('../../fonts/as.eot?vtd0fj#iefix') format('embedded-opentype'),
      url('../../fonts/as.ttf?vtd0fj') format('truetype'),
      url('../../fonts/as.woff?vtd0fj') format('woff'),
      url('../../fonts/as.svg?vtd0fj#as') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .as-icon {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'as' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } 
    
  .as-icon_ds-saved:before {
    content: "\e951";
  }
  .as-icon_pass-close:before {
    content: "\e94f";
  }
  .as-icon_pass-open:before {
    content: "\e950";
  }
  .as-icon_file-pdf:before {
    content: "\e94e";
  }
  .as-icon_download-light:before {
    content: "\e94c";
  }
  .as-icon_high-risk:before {
    content: "\e949";
  }
  .as-icon_check_cuicle:before {
    content: "\e94a";
  }
  .as-icon_cancel_circle:before {
    content: "\e94b";
  }
  .as-icon_card-cvv:before {
    content: "\e900";
  }
  .as-icon_card-visa:before {
    content: "\e901";
  }
  .as-icon_card-mc:before {
    content: "\e902";
  }
  .as-icon_card-amex:before {
    content: "\e903";
  }
  .as-icon_card-discover:before {
    content: "\e904";
  }
  .as-icon_card-default:before {
    content: "\e905";
  }
  .as-icon_brend-stripe:before {
    content: "\e906";
  }
  .as-icon_brend-amex:before {
    content: "\e907";
  }
  .as-icon_sort-up:before {
    content: "\e93d";
  }
  .as-icon_sort-down:before {
    content: "\e93e";
  }
  .as-icon_table-sort:before {
    content: "\e931";
  }
  .as-icon_plus-small:before {
    content: "\e932";
  }
  .as-icon_nav-right:before {
    content: "\e933";
  }
  .as-icon_eye:before {
    content: "\e934";
  }
  .as-icon_close-small:before {
    content: "\e935";
  }
  .as-icon_close-middle:before {
    content: "\e936";
  }
  .as-icon_video-btn:before {
    content: "\e941";
  }
  .as-icon_pgn-prev:before {
    content: "\e937";
  }
  .as-icon_pgn-next:before {
    content: "\e938";
  }
  .as-icon_chevron-down:before {
    content: "\e939";
  }
  .as-icon_arrow-down2:before {
    content: "\e92d";
  }
  .as-icon_timecode:before {
    content: "\e942";
  }
  .as-icon_close:before {
    content: "\e908";
  }
  .as-icon_chevron-right:before {
    content: "\e93a";
  }
  .as-icon_search:before {
    content: "\e909";
  }
  .as-icon_sc-youtube:before {
    content: "\e90a";
  }
  .as-icon_sc-twitter:before {
    content: "\e90b";
  }
  .as-icon_sc-instagram:before {
    content: "\e90c";
  }
  .as-icon_sc-facebook:before {
    content: "\e90d";
  }
  .as-icon_nav-left:before {
    content: "\e93b";
  }
  .as-icon_arrow-download:before {
    content: "\e93c";
  }
  .as-icon_plus-stroke:before {
    content: "\e92e";
  }
  .as-icon_pencil:before {
    content: "\e92f";
  }
  .as-icon_invoices:before {
    content: "\e930";
  }
  .as-icon_video-play:before {
    content: "\e90e";
  }
  .as-icon_user:before {
    content: "\e90f";
  }
  .as-icon_settings:before {
    content: "\e910";
  }
  .as-icon_sc-twittter-2:before {
    content: "\e911";
  }
  .as-icon_sc-facebook-2:before {
    content: "\e912";
  }
  .as-icon_sample:before {
    content: "\e913";
  }
  .as-icon_recomendation:before {
    content: "\e914";
  }
  .as-icon_player-volume_off:before {
    content: "\e915";
  }
  .as-icon_player-volume_med:before {
    content: "\e916";
  }
  .as-icon_player-volume_max:before {
    content: "\e917";
  }
  .as-icon_player-volume_low:before {
    content: "\e918";
  }
  .as-icon_player-skip_start:before {
    content: "\e919";
  }
  .as-icon_player-skip_end:before {
    content: "\e91a";
  }
  .as-icon_player-pause:before {
    content: "\e91b";
  }
  .as-icon_picture:before {
    content: "\e91c";
  }
  .as-icon_payment:before {
    content: "\e91d";
  }
  .as-icon_logout:before {
    content: "\e91e";
  }
  .as-icon_lock:before {
    content: "\e91f";
  }
  .as-icon_like:before {
    content: "\e920";
  }
  .as-icon_folder-sync:before {
    content: "\e921";
  }
  .as-icon_folder-music:before {
    content: "\e922";
  }
  .as-icon_file_format:before {
    content: "\e923";
  }
  .as-icon_download:before {
    content: "\e924";
  }
  .as-icon_clock:before {
    content: "\e925";
  }
  .as-icon_cart:before {
    content: "\e926";
  }
  .as-icon_calendar:before {
    content: "\e927";
  }
  .as-icon_brend-visa:before {
    content: "\e928";
  }
  .as-icon_brend-paypal:before {
    content: "\e929";
  }
  .as-icon_brend-mastercard:before {
    content: "\e92a";
  }
  .as-icon_brend-google:before {
    content: "\e92b";
  }
  .as-icon_brend-cashapp:before {
    content: "\e93f";
  }
  .as-icon_brend-bpm:before {
    content: "\e940";
  }
  .as-icon_brend-apple:before {
    content: "\e943";
  }
  .as-icon_brand-mech:before {
    content: "\e944";
  }
  .as-icon_arrow-down:before {
    content: "\e945";
  }
  .as-icon_add-rounded:before {
    content: "\e946";
  }
  .as-icon_share:before {
    content: "\e947";
  }
  .as-icon_player-play:before {
    content: "\e948";
  }
  .as-icon_sample-big:before {
    content: "\e92c";
  }
  .as-icon_sc-linkedin:before {
    content: "\e94d";
  }
  .as-icon_link:before {
    content: "\e9cb";
  }
  