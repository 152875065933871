.modal-wild {
   width: 100%;
   display: flex;
   height: 100vh;
   align-items: center;
   justify-content: center;
   

   &__content {
        background-color: #101010;
        width: 100%;
        min-width: 100%;
        position: relative;
        max-height: 100vh;
        overflow-y: scroll;
        padding: 24px 0;        
    }

    &__close {
        position: absolute;
        top: 6px;
        right: 10px;
        color: #ffffff;
        font-size: 22px;
    }
}

.modal-form {
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    max-width: 404px;
    margin: 0 auto;

    .head-middle {
        margin-bottom: 26px;
    }

    &__blc {
        margin-top: 20px;
    }
    .submit-row {
        margin-bottom: 40px;
        .btn {
            display: block;
            width: 100%;
        }
    
        .btn.btn-primary {
    
        }
        .btn.btn-text{
            margin-top: 10px;
            color: $red-color;           
            font-size: 16px;
            font-weight: 500;
            text-transform: uppercase;
            padding: 13px; 

            &:hover {
                color: $darkred-color;
            }           
        }

        

    }
    
}
.main-modal {    
    //overflow-y: auto;
    width: 100%;
    margin: 0 auto;
    max-width: 425px;    
    height: 100vh;
    display: flex;
    align-items: center;
    
    &__container {
        max-height: 100vh;
        background-color: #131313;
        width: 100%;
        position: relative;
        border-radius: 8px;
        padding: 20px 30px 32px 30px;       
        overflow-y: auto;
    }
    &__close {
        position: absolute;
        top: 14px;
        right: 14px;
        color: #ffffff;
        opacity: 0.46;
        font-size: 16px;

        &:hover, &:focus {
            opacity: 1;
        }

    }
    &__content {
        color: #FFFFFF;
        font-family: $main-font;       
        letter-spacing: 0;

    }
    &__logo {
        text-align: center;
        margin-bottom: 24px;
    }
    &__head {
        color: #FFFFFF;        
        font-size: 22px;
        font-weight: 300;        
        line-height: 1.18;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 18px;
    }
    &__big-head {        
        font-weight: 700;
        font-size: 28px;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
    }
    &__subhead {
        font-weight: 400;
        font-size: 22px;
        line-height: 1.3;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 30px;
    }
    
    &__act {
        text-align: center;
        margin-top: 18px;
        
        .btn.btn-primary {
            padding: 14px 42px;
        }
    }
    &__btns {
        text-align: center;
        margin-top: 18px;
        .btn {
            margin-bottom: 12px;
            width: 100%;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .btn.btn-primary {
            padding: 14px 42px;
        }
        .btn.btn-second {
            padding: 12px 42px;
        }
    }
    &__msg {
        font-size: 16px;
        font-weight: 300;
        text-align: center;
        line-height: 1.2;
    }
    &__msg.single {
       margin-bottom: 40px;
       max-width: 340px;
       margin-left: auto; 
       margin-right: auto; 
    }
}

.modal-radio {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    padding-bottom: 15px;    

    &__wrap {
        border-bottom: 1px solid rgba(255, 255, 255, 0.21);
        margin-bottom: 15px;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }
    }
    &__check {
        display: block;
        height: 18px;
        width: 18px;
        border: 1px solid #FFFFFF;
        margin-right: 9px;
        border-radius: 50%;
        position: relative;
        transition: all 0.3s ease;

        &::before {
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            position: absolute;
            top: 3px;
            left: 3px;
            transform: scale(0);
            border-radius: 50%;
            background-color: $red-color;
            transition: all 0.3s ease;
        }
    }
    &__label {
        text-align: left;
        line-height: 1.2;
    }
    &__name {
        display: block;
        font-size: 18px;
        font-weight: bold;

        .as-icon {
            font-size: 16px;
            display: inline-block;
            width: 24px;
        }
    } 
    &__note {
        display: block;
        font-size: 16px;
        font-weight: 300;
    }

    input {
        display: none;

        &:checked ~ .modal-radio__check  {
            border-color: $red-color;

            &::before {
                transform: scale(1);
            }

        }
    }
}

.modal-msg-content {
    text-align: center;
    p {
        font-size: 16px;
        font-weight: 300;
    }
    .btn.btn-primary {
        margin-top: 24px;
    }
}

.modal-many-btns {
    max-width: 320px;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .btn {
        max-width: 45%;
    }
    .btn-primary.btn-primary_small {
        padding: 14px 24px;
        font-size: 14px;
    }
}
