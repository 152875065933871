//stripe
.form-field { 
    .StripeElement {
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        background-color: #FFFFFF;
        padding: 7px 12px;

        &.StripeElement--focus {
            box-shadow: 0 0 5px 0 rgba(255,46,46,0.79);
        }

        &.StripeElement--invalid {
            border-color: $red-color;
        }
    }
}