html {
    overflow-y: scroll;
  
}
body, #as-app{
  font-family:  $main-font;
  background: $bg-color;
  color: $light-color;
  line-height: 1.56;
  font-weight: 400;
  overflow-x: hidden;
  min-height: 100vh;
  text-align: left;
  letter-spacing: 0;
}


.content {
  margin: 0 auto;
  max-width: 980px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;

  @include breakpoint(m) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @include breakpoint(s) {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.content-tight {
  margin: 0 auto;
  max-width: 412px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}

.content-medium {
  margin: 0 auto;
  max-width: 812px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}


