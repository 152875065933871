.form-section {
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: #101010;
}

.form-section--radial {
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(ellipse at top, rgba(0,0,0,0) 0%, rgba(16,16,16,0.6) 50%, rgba(16,16,16,0.9) 100%);
    }
    .content, .content-tight {
        z-index: 1;
        position: relative;
    }
}

.cs-sec {
    text-align: center;
    background-color: #000000;
    padding-top: 76px;
    padding-bottom: 84px;

    @include breakpoint(s) {
        padding-top: 46px;
        padding-bottom: 44px;
    }

    &__title {       
        font-size: 32px;
        font-weight: 500;        
        line-height: 1.2;
        margin-bottom: 28px;

        @include breakpoint(s) {
            font-size: 28px;
            margin-bottom: 24px;
            word-wrap: break-word;
            white-space: normal;
        }
    } 
    &__desc {
        max-width: 780px;
        margin-left: auto;
        margin-right: auto; 
        font-size: 16px;
        font-weight: 300;
    } 
}
//
.entity-sec {
    
    background-color: #101010;
    padding-top: 80px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;

    @include breakpoint(t) {
        background-size: auto auto;
    }
    
}
.entity-sec__top {
    text-align: center;
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 82px;

    .head-shadow {
        margin-bottom: 0;
        line-height: 1;
    } 
    .head {
        margin-top: -0.5em;
        margin-bottom: 10px;
    }   

    &-desc {        
        font-size: 16px;
        font-weight: 300;
    }
}

.related-sec {
    background-color: #101010;
    padding-top: 40px;
    
    &__title {        
        font-size: 28px;
        font-weight: 500;       
        line-height: 1.2;
        text-transform: uppercase;
        margin-bottom: 40px;
    }
    &__items {

    }
    
}
