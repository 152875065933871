.upload-page {
    background-color: #101010;
}
.upload-form {
    &__sec {
        padding-top: 44px;
        padding-bottom: 28px;
    }
    &__top {
        margin-bottom: 60px;
    }
    &__top-msg {
        font-size: 16px;
        font-weight: 300;
        line-height: 1.56;
        text-align: center;
    }
    &__checks {
        margin-bottom: 32px;
        
        .checkbox-field, .radio-field{

            margin-bottom: 12px;
            width: 100%;

            &__label {
                font-size: 18px;
                font-weight: 500;
                line-height: 1.2;
            }
        }
        
    }
   
}

.form-step {

    &__top-desc {
        max-width: 780px;
        margin: 0 auto 0 auto;
        font-size: 16px;
        font-weight: 300;        
        line-height: 1.5625;
        text-align: center;

        p {
            margin-bottom: 10px;
        }
    }    

    &__body {
        padding-bottom: 80px;


    }

    &__fieldset {
        margin-bottom: 30px;

        .form-field textarea{
            min-height: 180px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

}

.submit-row_txt .link-thin {
    font-size: 16px;
    margin-left: 5px;
}

.submit-row_txt {


    @include breakpoint(s) {
        flex-wrap: wrap;
    }

    .submit-row__btn-col {

        @include breakpoint(s) {
            width: 100%;
            text-align: center;
            margin-top: 10px;
        }
    }
}

.submit-row.submit-row_right {
    text-align: right;

    @include breakpoint(s) {
        text-align: center;
    }

    .btn.btn-primary {
        min-width: 170px;
    }
}

.form-layout {
    .submit-row.submit-row_right {
        margin-top: 40px;
    }

}
.review-video {
    max-width: 600px;
    margin: 20px auto 30px;

    &__inner {
        width: 100%; 
        height: 0px; 
        position: relative; 
        padding-bottom: 56.250%;

        iframe {
            width: 100%; 
            height: 100%; 
            position: absolute;
        }
    }

}