button, a {
    transition: all 0.3s ease;
    font-family:  $main-font;
    cursor: pointer;
    
    &:disabled {
        cursor: default;
    }
}

.btn {
    display: inline-block;
    border:none;
    outline: none;
    font-family: $main-font;
    cursor: pointer;

    &:disabled {
        cursor: default;
    }

}
.btn-primary {
    font-size: 16px;
    text-transform: uppercase;
    background-color: $red-color;
    color: $light-color;
    text-align: center;
    padding: 14px 38px;
    border-radius: 26px;
    box-shadow: 0 2px 14px 0 rgba(0,0,0,0.5);

    &:visited {
        color: $light-color;
    }
    &:hover {
        background-color: $darkred-color; 
        color: $light-color;      
    }
    &:active{
        //background-color: yellowgreen;
        color: $light-color;
        background-color: $red-color;
        box-shadow: 0 2px 14px 0 rgba(255,46,46,0.6)
    }
    &:disabled{
        background-color: $dark-color;        
    }
    &:focus {
        //background-color: thistle;
    }

}


.btn-second {
    font-size: 16px;
    text-transform: uppercase;
    background-color: transparent;
    color: $red-color;
    text-align: center; 
    border: 2px solid $red-color; 
    padding: 16px 38px;
    border-radius: 26px;

    &:visited {
        color: $red-color; 
    }
    &:hover {
        color: $darkred-color;
        border-color: $darkred-color;       
    }
    &:active{
        color: $red-color;
        box-shadow: 0 2px 14px 0 rgba(255,46,46,0.6)
    }
    &:disabled{
        opacity: 0.6;        
    } 
}
.btn-fw {
    width: 100%;
}

.btn-square {
    font-size: 13px;
    font-weight: 500;
    background-color: $red-color;
    color: $light-color;
    text-align: center;
    text-transform: uppercase;
    padding: 7px 22px;

    &:hover {
        background-color: $darkred-color;
    }
    &:disabled{
        background-color: $dark-color;        
    }
}


.link {
    color: $red-color;
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

.link-thin {
    color: $darkred-color;
    text-decoration: underline;
    font-weight: 300;
    border: none;
    background: transparent;
    cursor: pointer;
}

.btn-icon {
    display: inline-block;
    border:none;
    outline: none;
    background-color: transparent;
    color: #818EAE;
    opacity: 0.3;
    font-size: 14px;
    padding: 5px 7px;

    &:hover, &:focus, &:active {
        opacity: 1;
    }
}
.btn-song-act {
    display: inline-block;   
    outline: none;
    background-color: transparent;
    border-radius: 50%;
    height: 28px;
    width: 28px;
    border: 1px solid #D0021B;
    color:  #D0021B;
    font-size: 10px;
    line-height: 26px;
    margin-top: 2px;


    &:hover, &:focus, &:active {
        color: $red-color;
        border-color: $red-color;
    }

}

.btn-play {
    display: inline-block;   
    outline: none;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 72px;
    margin-left: -36px;
    margin-top: -36px;
}

.btn-buy {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    .as-icon {
        font-size: 14px;
        margin-right: 5px;
    }
    .price {
        font-size: 14px;
        font-weight: 500;
    }
    .text {
        font-size: 14px;
        font-weight: 500;
    }
    &:visited, &:active {
        color: #FFFFFF;
    }
}
//   height: 36px;
//   width: 93px;
  

.btn-buy.btn-buy_card {
    border: 1px solid #979797;
    border-radius: 17.5px;
    min-width: 90px;
    padding: 6px 3px;


    &:hover, &:focus {
        border-color: $red-color;
        background-color: $red-color;
    }
}
.btn-buy.btn-buy_product {
    background-color: $red-color;
    border-radius: 20px;
    border: none;
    padding: 9px 18px;
    
    &:hover, &:focus {       
        background-color: $darkred-color;
    }

}
.btn-reset {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    background-color: transparent;
    color: $red-color;
    text-align: center; 
    border: 1px solid $red-color; 
    padding: 7px 16px;
    border-radius: 26px;
    font-size: 14px;
    font-weight: 500;

    .as-icon {
        font-size: 14px;
        margin-right: 5px;
    }
    &:hover, &:active {
        color: $darkred-color;
        border-color: $darkred-color;
    }


}

.btn-round-iconed {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    text-align: center; 
    background-color: $red-color; 
    padding: 7px 18px 7px 8px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;

    .as-icon {
        font-size: 20px;
        margin-right: 7px;
    }

    &:hover, &:active {
        background-color: $darkred-color;        
    }

}
.btn-text {
    background-color: transparent;
    border: none;
    padding: 0;
}
.btns-sort {
    display: inline-block;    
}

.btn-sort {
    background-color: transparent;
    border: none;
    color: inherit;
    display: block;
    line-height: 0;
    padding: 2px;
    font-size: 5px;
}

.btn-act-iconed {
    background-color: transparent;
    border: none;
    display: inline-block;
    color: #343333;    
    font-size: 20px;
    padding: 5px;
    margin: 0 2px;

    .as-icon_eye {
        font-size: 16px;
    }

    &:hover, &:focus {
        color: #ffffff;
    }

}

.link-medium-wild {
    color:$red-color;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.5px;

    &:hover {
        color: $darkred-color;
    }
    
}
