.top-banner {
   
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
    padding-top: 36px;
    padding-bottom: 36px;

    .head {
        margin-bottom: 12px;
    }
    
    &__info {    
        font-size: 16px;
        font-weight: 300;        
        line-height: 1.56;
        max-width: 780px;
        margin: 0 auto 40px auto;
        p {            
            margin-bottom: 20px;
        }
        strong {
            font-weight: 500; 
        }
        
    }
    &__btns {
        
    }

}

.banner-filter {
    position: relative;
    z-index: 0;
    overflow-y: hidden;

    &::before {
        content: '';
        position: absolute;
        display: block;
        height: 800px;
        top: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(0deg, rgba(16,16,16, 1), rgba(16,16,16, 0)); 
        z-index: -1;      
        
    }
    .content {
        z-index: 1;
    }
}

.banner-filter.banner-filter_fw {
    &::before {
        bottom: 0;
        height: auto;
    }
}
.banner-filter.banner-filter_hide {
    
    &::after {
        content: '';
        position: absolute;
        display: block;        
        top: 800px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(16,16,16, 1);       
        z-index: -1;    
    }
}
