.artists-blc {
    @include make-row(18px);
    align-items: stretch;
    justify-content: flex-start;
}

.artist-card {
    @include make-col(2, 18px, 10);
    margin-bottom: 18px;

    
    @include breakpoint(m) {
        @include change-col-width(3, 12);
    }
    @include breakpoint(s) {
        @include change-col-width(4, 12);
    }
    @include breakpoint(xs) {
        @include change-col-width(6, 12);
    }
}
