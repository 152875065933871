.singles-blc {
    @include make-row();
    align-items: stretch;
}

.single-card {
    @include make-col(6);
    margin-bottom: 6px;

    
    @include breakpoint(m) {
        @include change-col-width(12);
    }
    
}
