.form-submit-row {
    margin-bottom: 30px; 
    margin-top: 30px;
}

.form-row {
    @include make-row();

    &_bottom {
        align-items: flex-end;

        @include breakpoint(m) {
            align-items: flex-start;
        }
    }

}

.form-col_2 {
    @include make-col(6);

    @include breakpoint(m) {
        @include change-col-width(12);
    }
}

.submit-row_txt {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .as-text {

    }
}

.submit-row__btn-col {

}

.form-layout {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;

    .submit-row_txt {
        margin-top: 10px;

        .btn-primary {
            min-width: 168px;
        }
        
        
    }
}
.submit-row {
    .btn-second {
        padding: 12px 38px;            
    }
}


.form-col_2.full-height {
    justify-self: stretch;
    .form-field {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: calc(100% - 20px);

        .form-upload {
            flex-grow: 1;
        }
    }

}
